// const addOwnerPage = document.querySelector('#add-owner');
// const editOwnerPage = document.querySelector('#edit-owner');

// if (addOwnerPage || editOwnerPage) {
// 	// const selectLegal = document.querySelector('[name="legal_form_id"]')
// 	const legalEntityFields = document.querySelectorAll('.legal-entity')
// 	const individualEntityFields = document.querySelectorAll('.individual')

// 	const LEGAL_ENTITY = 1;
// 	const INDIVIDUAL = 2;


// 	function hideField(selector) {
// 		if (!selector.classList.contains('d-none')) {
// 			selector.classList.add('d-none');
// 		}
// 	}

// 	function showField(selector) {
// 		if (selector.classList.contains('d-none')) {
// 			selector.classList.remove('d-none');
// 		}
// 	}

// 	$('select[name="legal_form_id"]').on("select2:select", async function (e) {

// 		if (e.params.data.id == LEGAL_ENTITY) {

// 			legalEntityFields.forEach(field => {
// 				showField(field)
// 			})

// 			individualEntityFields.forEach(field => {
// 				hideField(field)
// 				field.querySelectorAll('input').forEach(item => {
// 					item.value = ''
// 				})
// 			})
// 		}

// 		if (e.params.data.id == INDIVIDUAL) {

// 			legalEntityFields.forEach(field => {
// 				hideField(field)
// 				field.querySelectorAll('input').forEach(item => {
// 					item.value = ''
// 				})
// 			})

// 			individualEntityFields.forEach(field => {
// 				showField(field)
// 			})
// 		}
// 	});
// }